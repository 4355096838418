/**
 * @name: 统计分析-档口出库统计接口文件
 * @author: lili
 * @date: 2023-08-04 17:46
 * @description： 统计分析-档口出库统计接口文件
 * @update: 2023-08-04 17:46
 */
import {get, postJ, postW} from "@/request"
import {IPageRes} from "@/apis/page";
import {ICheckout, ICheckoutParams} from "@/apis/statistics/checkout/types";
/**
 * 档口出库统计查询
 * @param param
 * @returnspage=${data.page}&limit=${data.limit}
 */
export const stallOutboundByPageApi = (param: ICheckoutParams) => get<IPageRes<ICheckout>>(`/admin/stallStatistics/queryStallOutboundByPage`, param)
/**
 * 档口出库统计导出
 * @param data
 * @returns
 */
export const stallOutboundExportApi = (data: ICheckoutParams) => get("/admin/stallStatistics/stallOutboundExport", data, "blob")


/**
 * 档口销售出库详情
 * @param param
 * @returns
 */
export const stallSaleOutboundDetailApi = (data: ICheckoutParams) => postW(`/admin/stallStatistics/queryStallSaleOutboundDetail?page=${data.page}&limit=${data.limit}`, data)

/**
 * 档口报损详情
 * @param param
 * @returns
 */
export const stallReportingLossesDetailApi = (data: ICheckoutParams) => postW(`/admin/stallStatistics/queryStallReportingLossesDetail?page=${data.page}&limit=${data.limit}`, data)

/**
 * 档口销售退货入库详情
 * @param param
 * @returns
 */
export const stallSaleTurnStorageDetailApi = (data: ICheckoutParams) => postW(`/admin/stallStatistics/queryStallSaleTurnStorageDetail?page=${data.page}&limit=${data.limit}`, data)
