
/**
 * @name: 统计分析-档口出库统计
 * @author:
 * @date: 2023-08-04 17:53
 * @description： 统计分析-档口出库统计
 * @update: 2023-08-04 17:53
 */
import {Component, Vue} from "vue-property-decorator"
import type {ICrudOption} from "@/types/m-ui-crud"
import {ICheckout, ICheckoutParams} from "@/apis/statistics/checkout/types";
import {deepCopy, exportFile, getPreAndNextTime} from "@/utils/common";
import {
  stallOutboundByPageApi,
  stallOutboundExportApi,
  stallReportingLossesDetailApi,
  stallSaleOutboundDetailApi,
  stallSaleTurnStorageDetailApi
} from "@/apis/statistics/checkout";

@Component({})
export default class statisticsCheckout extends Vue {
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: any = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: ICheckoutParams = {
    page: 1,
    limit: 10,
    queryTimeStart: '',//	开始时间
    queryTimeEnd: ''//	结束时间
  }
  // 表单参数
  modelForm: any = {
    page: 1,
    limit: 10,
    loading:false,
    tableData: []
  }
  dialogTitle: string = '';
  dialogVisible: boolean = false;
  dialogType: number = 1;
  // crud配置信息
  crudOption: ICrudOption = {
    menu: false,
    column: [
      {
        label: "日期",
        prop: "time",
        width: "150",
        align: "center",
        hide: true,
        search: true,
        type: "daterange",
        // @ts-ignore
        defaultTime: ["06:00:00", "05:59:59"],
        // @ts-ignore
        onSearchChange:()=>{
          this.getList();
        }
      },
      {
        label: "档口名称",
        prop: "gateIdList",
        search: true,
        searchMultiple: true,
        hide: true,
        type: "select",
        dicUrl: "/api/admin/gate/getGateList",
        dicFormatter: (res: any) => ({list: res.data, label: 'gateName', value: 'id'}),
      },
      {
        label: "档口名称",
        prop: "gateName",
        width: 150,
        align: "center"
      },
      {
        label: "商品名称",
        prop: "productName",
        search: true,
        align: "center",
        width: 150
      },
      {
        label: "采购单位",
        prop: "purchaseUnit",
        align: "center",
      },
      {
        label: "成本价1（按采购数计算）",
        prop: "costPrice1",
        align: "center",
        width: 180
      },
      {
        label: "成本价2（按实际入库数计算）",
        prop: "costPrice2",
        align: "center",
        width: 210
      },
      {
        label: "档口销售出库数",
        prop: "checkoutQty",
        align: "center",
        width: 180,
        slot: true,
      },
      {
        label: "档口报损数",
        prop: "frmlossQty",
        align: "center",
        slot: true,
      },
      {
        label: "档口销售退货入库数",
        prop: "checkQty",
        align: "center",
        width: 180,
        slot: true,
      },
      {
        label: "档口实际入库数",
        prop: "realityOutboundQuantity",
        align: "center",
        width: 180,
      },
    ]
  }

  /**
   * 详情
   * @param row
   * @param type //1-checkoutQty ,2=frmlossQty,3=checkQty 1-档口销售出库详情 ,2=档口报损详情 ,3=档口销售退货入库详情
   */
  openDetail(row: ICheckout, index: number, type: number) {
    this.modelForm.loading=true;
    this.dialogTitle = type == 1 ? '档口销售出库详情' : type == 2 ? '档口报损详情' : '档口销售退货入库详情'
    this.dialogType = type;
    this.modelForm.page = 1;
    this.modelForm.gateId = row.gateId;
    this.modelForm.productId = row.productId;
    const form: any = this.modelForm;
    console.log(this.queryParam.time,'this.queryParam.time')
    if (this.queryParam.time && this.queryParam.time.length) {
      form.queryTimeStart = this.queryParam.time[0]
      form.queryTimeEnd = this.queryParam.time[1]
    } else {
      form.queryTimeStart = ''
      form.queryTimeEnd = ''
    }
    delete form.tableData
    this.modelForm.gateName = row.gateName;
    this.modelForm.productName = row.productName;
    this.modelForm.totalQty = type == 1 ? row.checkoutQty : type == 2 ? row.frmlossQty : row.checkQty;
    if (this.dialogType == 1) {
      this.getStallSaleOutboundDetailApi(form);
    } else if (this.dialogType == 2) {
      this.getStallReportingLossesDetail(form);
    }else {
      this.getStallSaleTurnStorageDetail(form)
    }
    this.dialogVisible = true;
  }

  /**
   * 档口销售出库详情 分页列表数据
   *
   */
  getStallSaleOutboundDetailApi(form: any) {
    delete form.tableData
    stallSaleOutboundDetailApi(form).then(e => {
      let {checkoutVoPage} = e;
      this.modelForm.tableData = checkoutVoPage.records;
      this.modelForm.total = checkoutVoPage.total;
      this.modelForm.loading=false;
      console.log(this.modelForm.tableData,'this.modelForm.tableData')
    })
  }

  /**
   * 档口报损详情  分页列表数据
   *
   */
  getStallReportingLossesDetail(form: any) {
    stallReportingLossesDetailApi(form).then(e => {
      let {frmlossRecordVoPage} = e;
      this.modelForm.tableData = frmlossRecordVoPage.records;
      this.modelForm.total = frmlossRecordVoPage.total;
      this.modelForm.loading=false;
    })
  }

  /**
   * 档口销售退货入库详情  分页列表数据
   *
   */
  getStallSaleTurnStorageDetail(form: any) {
    stallSaleTurnStorageDetailApi(form).then(e => {
      let {checkinVoPage} = e;
      this.modelForm.tableData = checkinVoPage.records;
      this.modelForm.total = checkinVoPage.total;
      this.modelForm.loading=false;
    })
  }

  /**
   * 切换分页 1页条数
   * @param val
   */
  handleSizeChange(val: number) {
    this.modelForm.loading=true;
    this.modelForm.limit = val;
    const form: any = this.modelForm;
    if (this.queryParam.time && this.queryParam.time.length) {
      form.queryTimeStart = this.queryParam.time[0]
      form.queryTimeEnd = this.queryParam.time[1]
    } else {
      form.queryTimeStart = ''
      form.queryTimeEnd = ''
    }
    if (this.dialogType == 1) {
      this.getStallSaleOutboundDetailApi(form);
    } else if (this.dialogType == 2) {
      this.getStallReportingLossesDetail(form);
    }else {
      this.getStallSaleTurnStorageDetail(form)
    }
  }

  /**
   * 切换分页 页数
   * @param val
   */
  handleCurrentChange(val: number) {
    this.modelForm.loading=true;
    this.modelForm.page = val;
    const form: any = this.modelForm;
    if (this.queryParam.time && this.queryParam.time.length) {
      form.queryTimeStart = this.queryParam.time[0]
      form.queryTimeEnd = this.queryParam.time[1]
    } else {
      form.queryTimeStart = ''
      form.queryTimeEnd = ''
    }
    delete form.tableData
    if (this.dialogType == 1) {
      this.getStallSaleOutboundDetailApi(form);
    } else if (this.dialogType == 2) {
      this.getStallReportingLossesDetail(form);
    }else {
      this.getStallSaleTurnStorageDetail(form)
    }
  }

  /**
   * 导出excel
   */
  exportExcel() {
    const form: ICheckoutParams = deepCopy(this.queryParam)
    if (this.queryParam.time && this.queryParam.time.length) {
      form.queryTimeStart = this.queryParam.time[0]
      form.queryTimeEnd = this.queryParam.time[1]
    } else {
      form.queryTimeStart = ''
      form.queryTimeEnd = ''
    }
    delete form.time
    if (!form.gateIdList) {
      form.gateIdList = []
    }
    stallOutboundExportApi(form).then(e => {
      exportFile(e, '档口出库统计.xlsx')
    })
  }

  getList() {
    this.tableLoading = true
    const query: ICheckoutParams = deepCopy(this.queryParam)
    if (this.queryParam.time && this.queryParam.time.length) {
      query.queryTimeStart = this.queryParam.time[0]
      query.queryTimeEnd = this.queryParam.time[1]
    } else {
      query.queryTimeStart = ''
      query.queryTimeEnd = ''
    }
    delete query.time
    if (query.gateIdList) {
      query.gateIdList = query.gateIdList.join(',');
    }
    stallOutboundByPageApi(query).then(e => {
      this.tableTotal = e.total
      this.tableData = e.list
      this.tableLoading = false
    })
  }

  reset() {
    const dateRange = getPreAndNextTime("06:00:00", "05:59:59")
    this.queryParam.time = dateRange;
    this.getList()
  }

  created() {
    this.reset()
  }
}
